import React from 'react';
import clsx from  'clsx';

import Layout from '../components/Layout';
import Title from '../components/Title/Title';
import CtaDescList from '../components/CtaDescList/CtaDescList';
import DynamicContent from '../components/DynamicContent/DynamicContent';
import References from '../components/References/References';
import Footnotes from '../components/Footnotes/Footnotes';

import EnlargeIcon from '../images/enlargeIcon.inline.svg';
import SafetySubsequentAdverseReactionsTableMobile from '../images/safety-second/safetySubsequentAdverseReactionsTableMobile.inline.svg';
import SafetySubsequentAdverseReactionsTable from '../images/safety-second/safetySubsequentAdverseReactionsTable.inline.svg';
import SafetySubsequentsAbnormalitiesTableMobile from '../images/safety-second/safetySubsequentsAbnormalitiesTableMobile.inline.svg';
import SafetySubsequentsAbnormalitiesTable from '../images/safety-second/safetySubsequentsAbnormalitiesTable.inline.svg';
import JumpLink from '../components/JumpLink/JumpLink';
import GlobalWrapper from '../components/GlobalWrapper/GlobalWrapper';

import { GtagHead } from '../components/GTAG';

export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>Safety | LOQTORZI® (toripalimab-tpzi)</title>
      <meta name='description'
        content='Learn more about the LOQTORZI® safety demonstrated in the POLARIS-02 trial.'/>
    </>
  )
}

export default function SafetySecond() {
  return (
    <Layout>
      <Title pageName={'Safety'}>
        Safety—<br className='tablet:hidden' />
        second-line<br />
        subsequent <br className='tablet:hidden' />
        treatment
      </Title>

      <DynamicContent header={ <h2>POLARIS-02 SAFETY</h2> }>
        <div className='wrapperContent !pb-0'>
          <div className='title mt-[2rem]'>
            <h3>LOQTORZI<sup>®</sup> adverse events<sup>1</sup></h3>
          </div>
          <div className='paragraph font-MontserratMedium !text-center tablet:!text-left'>
            <span>Adverse reactions (≥10%) in patients with previously treated unresectable or metastatic NPC who received LOQTORZI<sup>®</sup> in POLARIS-02</span>
          </div>
          <div className='flex items-center justify-end gap-[1rem] font-MontserratMedium text-[1rem] text-gray-250  tablet:hidden'>
            <span>PINCH TO ENLARGE</span>
            <EnlargeIcon></EnlargeIcon>
          </div>

          <SafetySubsequentAdverseReactionsTableMobile className={clsx('tablet:hidden w-full h-auto m-auto mt-[1.3rem]')} />
          <SafetySubsequentAdverseReactionsTable className={clsx('hidden w-full h-auto mx-auto mt-[1.5rem] mb-[3rem] tablet:block')} />

          <Footnotes className={'mt-[3rem]'} items={[
            {
              bullet: <span>*</span>,
              text: <span>Toxicity was graded per NCI CTCAE v4.03.<sup>1</sup></span>
            },
            {
              bullet: <sup>†</sup>,
              text: <span>Includes hypothyroidism, thyroiditis, triiodothyronine decreased, and triiodothyronine free decreased.<sup>1</sup></span>
            },
            {
              bullet: <sup>‡</sup>,
              text: <span>Includes fatigue and asthenia.<sup>1</sup></span>
            },
            {
              bullet: <sup>§</sup>,
              text: <span>Includes cough and productive cough. <sup>1</sup></span>
            },
            {
              bullet: <sup>‖</sup>,
              text: <span>Includes musculoskeletal pain and myalgia.<sup>1</sup></span>
            },
            {
              bullet: <sup>¶</sup>,
              text: <span>Includes dermatitis allergic, eczema, and rash.<sup>1</sup></span>
            }
          ]}
        />


          <div className='mt-[2.5rem] font-MontserratBold text-[1.7rem] text-blue-900'>Serious and fatal adverse reactions</div>
          <ul className='bullet-list mt-[1rem]'>
            <li>
              Serious adverse reactions occurred in 24% of patients who received LOQTORZI<sup>®</sup>
              <ul className='bullet-list-dash leading-[1.3]'>
                <li>Serious adverse drug reactions in ≥2% were pneumonia (4.7%), abnormal hepatic function (2.6%), and hyperbilirubinemia (2.1%)</li>
              </ul>
            </li>
            <li>Fatal adverse reactions occurred in 3.7% of patients who received LOQTORZI<sup>®</sup>, including death not otherwise specified (1.6%), tumor hemorrhage (0.5%), hepatic failure and thrombocytopenia (0.5), hyponatremia (0.5%), and sudden death (0.5%)</li>
          </ul>
          <div className='mt-[2.5rem] font-MontserratBold text-[1.7rem] text-blue-900'>Discontinuations and dosage interruptions</div>
          <ul className='bullet-list mt-[1rem]'>
            <li>Permanent discontinuation of LOQTORZI<sup>®</sup> due to an adverse reaction occurred in 9% of patients
              <ul className='bullet-list-dash'>
                <li>Adverse reactions resulting in permanent discontinuation of LOQTORZI<sup>®</sup> in ≥1% of patients were pneumonia (1.1%), abnormal hepatic function (1.1%), and hyperbilirubinemia (1.1%)</li>
              </ul>
            </li>
          </ul>
          <ul className='bullet-list mt-[1rem]'>
            <li>
            Dosage interruptions due to an adverse reaction occurred in 23% of patients
              <ul className='bullet-list-dash leading-[1.3]'>
                <li>Adverse reactions which required dosage interruptions in ≥1% were pneumonia (2.1%), thrombocytopenia (2.1%), fatigue (1.6%), hyperbilirubinemia (1.6%), anemia (1.1%), decreased appetite (1.1%), abnormal hepatic function (1.1%), hypothyroidism (1.1%), and pneumonitis (1.1%)</li>
              </ul>
            </li>
          </ul>
          <div className='title mt-[2rem]'>
            <h3>POLARIS-02 laboratory abnormalities</h3>
          </div>
          <div className='paragraph font-MontserratMedium !text-center tablet:!text-left'>
            <span>Select laboratory abnormalities (≥20%) that worsened from baseline in patients with previously treated unresectable or metastatic NPC who received LOQTORZI<sup>®</sup> in POLARIS-02</span>
          </div>
          <div className='flex items-center justify-end gap-[1rem] font-MontserratMedium text-[1rem] text-gray-250  tablet:hidden'>
            <span>PINCH TO ENLARGE</span>
            <EnlargeIcon></EnlargeIcon>
          </div>

          <SafetySubsequentsAbnormalitiesTableMobile className={clsx('tablet:hidden w-full h-auto m-auto')} />
          <SafetySubsequentsAbnormalitiesTable className={clsx('hidden w-full h-auto mx-auto mt-[1.5rem] pb-[3rem] tablet:block')} />

        </div>
      </DynamicContent>
      <GlobalWrapper className='pt-[3rem] pl-[0.8rem] pr-[1.5rem] border-left tablet:pl-[3rem]'>
          <Footnotes items={[
            {
              bullet: <sup>#</sup>,
              text: <span>Toxicity is graded per NCI CTCAE v4.03. The denominator used to calculate the rate varied from 141 to 186 based on the number of patients with a baseline value and at least one post-treatment value.</span>
            },
            {
              bullet: '',
              text: <span>NCI CTCAE=National Cancer Institute Common Terminology Criteria for Adverse Events; R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma.
              </span>
            }
          ]}
        />
        </GlobalWrapper>

      <JumpLink content={{title:'See POLARIS-02 efficacy', link:'/loqtorzi-efficacy-second-line-subsequent-treatment/', class:'special-top'}}/>

      <References
        items={[
          <span>LOQTORZI<sup>®</sup> (toripalimab-tpzi) Prescribing Information. Redwood City, CA: Coherus BioSciences, Inc.</span>
        ]} border={true}
      />
      <GlobalWrapper className='pl-[0.8rem] pr-[1.5rem] border-left tablet:pl-[3rem]'>
        <div className='pb-[1.4rem]'>&nbsp;</div>
      </GlobalWrapper>
      <CtaDescList content={[
        {
          cta: {
            text: <>LOQTORZI<sup>®</sup> at a glance</>,
            url:'/loqtorzi-at-a-glance/'
          },
          text: <div>This module offers you quick <br className='desktop:hidden' />access to key information <br className='desktop:hidden' />about LOQTORZI<sup>®</sup> <br className='hidden large:block' />
            to help you <br className='desktop:hidden' />support your patients.</div>
        }, {
          cta: {
            text: <span>Interactive <br/>
              Prescribing Information </span>,
            url:'/loqtorzi-prescribing-information/'
          },
          text: <div>Explore the details of POLARIS-02
            <br className='desktop:hidden' /> trial data in the interactive
            <br className='desktop:hidden' /> Prescribing Information.</div>
        },
        {
          cta: {
            text: 'Get Updates',
            url: '/get-updates'
          },
          text: <div>Register to receive the latest <br className='desktop:hidden' />information and announcements <br className='desktop:hidden' />about LOQTORZI<sup>®</sup>.</div>
        }
      ]} />

    </Layout>

  );
}